/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-handler-names */
import React, { createRef, useContext, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import _debounce from 'lodash/debounce';
import { withAuth0 } from '@auth0/auth0-react';
import { SvgClose } from '@ntuctech/devex-tangram/Icon';
import { ButtonIcon, Grid, ButtonText } from '@ntuctech/devex-tangram';
import { useRouter } from 'next/router';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import HamburgerMenuIcon from '../../icons/HamburgerMenu';
import { RESIZING_TIMER, SCREEN_SIZE, breakpoints } from '../../../lib/mediaQuery';
// light
import FPG50 from '../../icons/svg/Original.svg';
// Dark
import FPWhite from '../../icons/svg/OriginalDark.svg';
import { Cart } from './Cart';
import { CustomiseVoucherContext } from '../../Context/Context';
import { B2B, B2C } from '../../../constants';
import { GTM_ENV_VAR as GEV, PAGE_TYPES, SCREEN_NAME } from '../../../lib/eventTrackerFunction';
import { EventTrackingContext } from '../../EventTrackingProvider/EventTrackingProvider';
import Popup from './Popup';
import HeaderBusiness from './HeaderBusiness';
import CancelModal from '../../CustomiseVoucher/component/CancelModal';
import { AMP_EVENT_NAME } from '../../../lib/amplitude';
import { disablePageScroll, getToggleLabel } from '../../../lib/utils';
import { SplitContext } from '../../../SplitContext/splitConfiguration';
import SPLIT_FEATURES from '../../../SplitContext/features';
import { newContactUsUrl } from '../../../lib/navigation';
import useBusinessEntityData from '../../../hooks/useBusinessEntityData';
import { useAppContext } from '../../../lib/contextProviders/contexts/appContext';
import { useCartContext } from '../../../lib/contextProviders/contexts/cartContext';
import { useUserContext } from '../../../lib/contextProviders/contexts/userContext';

const GlobalPopoverStyle = createGlobalStyle`
  .react-tiny-popover-container {
    z-index: 10006; 
  }
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  max-height: 3.75rem;

  ${SCREEN_SIZE.From.Tablet} {
    max-height: 5rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    display: flex;
    max-height: 100%;
  }
`;

const StyledHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${SCREEN_SIZE.Below.MobileL} {
    width: 100%;
    ${(props) => props.isb2b && 'position: relative;'};
  }
  .center-logo {
    margin: 0 auto !important;
  }
  #hamburger {
    display: none;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    #hamburger {
      display: ${(p) => (p.isPaymentPage ? 'none' : 'block')};
    }
  }
`;

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  z-index: 9998;
  position: fixed;
  top: 0;
  padding: 0 1.25rem;
  height: 4.5rem;

  ${SCREEN_SIZE.Below.MobileL} {
    height: 4.5rem;
    padding: ${(p) => (p.isPaymentPage ? '1rem 0 1rem 1rem;' : '0.5rem 0.25rem 0.75rem;')};
  }

  ${SCREEN_SIZE.Only.Tablet} {
    padding: 18px 2rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    padding: 1.25rem 2.5rem;
  }
  background-color: ${(props) => (props.isBusiness ? '#0B2E65' : '#fff')};
`;

const HamburgerMenuIconWrapper = styled(HamburgerMenuIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #696969;
  ${SCREEN_SIZE.Only.Tablet} {
    margin-right: 1.25rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    g {
      ${(p) => p.isbusiness === 'true' && 'fill: #fff !important;'}
    }
    margin: 0.75rem;
  }
`;

const StyledButton = styled(ButtonText)`
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  ${(props) => props.isLogout && !props.isB2b && 'color: #c10b3A'}
  ${(props) => props.haveMargin && 'margin-right: 2rem !important;'};
  ${SCREEN_SIZE.Only.Tablet} {
    ${(props) => props.haveMargin && 'margin-right: 0.5rem !important;'};
  }
  color: ${(props) => (props.isB2b ? '#fff !important;' : '#1454b8;')};
  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  &:active:before {
    box-shadow: none !important;
  }
  &:focus:before {
    box-shadow: none !important;
  }
`;

const GlobalTopPaddingBody = createGlobalStyle`
  body {
    padding-top: 57px !important;
    background: #F4F5F7 !important;
    overflow: auto;
    height: 100%;
  }
  img {
    width: 100%;
  }
`;

const CartWrp = styled.div``;

const Login = styled.span`
  a {
    font-style: normal;
    line-height: 24px;
    text-decoration: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const CloseBtn = styled.span`
  position: relative;
  cursor: pointer;
  svg {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    max-width: 1.5rem !important;
    max-height: 1.5rem !important;
    path {
      fill: ${(p) => (p.isB2bEnabled ? '#ffffff' : '#1557BF')};
    }
  }
`;

const BrandIcons = styled.div`
  .no-spacing {
    padding: 0;
    margin: 0;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  img {
    width: 6.625rem !important;
    height: 1.625rem;
    cursor: pointer;
  }
  ${SCREEN_SIZE.From.Tablet} {
    img {
      width: 148px;
    }
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-right: auto;
    .max-height {
      height: 1.5rem;
    }
    img {
      width: 5.5rem;
      cursor: pointer;
    }
  }
`;

const StyledImg = styled.img`
  ${SCREEN_SIZE.Below.MobileL} {
    height: 1.375rem;
  }
`;

const StyledBrandIcons = styled(Grid)`
  padding: 0 !important;
  display: flex;
  height: 2.25rem;
  width: auto;
  ${SCREEN_SIZE.Below.MobileL} {
    display: block;
    height: unset;
  }
`;

const RenderLogin = (props) => {
  const { loginWithRedirect, destroyeeAllNookies, logout, isMobile, isMyordersEnable, router } =
    props;
  const { cartState } = useCartContext();
  const { isUserAccHasBusinessEntity, isB2BSite } = useBusinessEntityData();
  const {
    userState: { isLoggedIn, isLoggingIn },
  } = useUserContext();

  return (
    <>
      <Login>
        {!isLoggingIn && (
          <>
            {!isLoggedIn ? (
              <StyledButton
                size="m"
                isB2b={isB2BSite}
                data-testid="login-button"
                haveMargin={!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity)}
                onClick={() => {
                  loginWithRedirect({
                    redirectUri: window.location.origin,
                    appState: { returnTo: window.location.href },
                  });
                }}
              >
                Log in/Sign up
              </StyledButton>
            ) : (
              <>
                {isB2BSite && isMyordersEnable && (
                  <>
                    <StyledButton
                      size="m"
                      isB2b={isB2BSite}
                      haveMargin
                      data-testid="contact-us-button"
                      onClick={() => {
                        window.open(newContactUsUrl, '_blank');
                      }}
                    >
                      Contact us
                    </StyledButton>
                    {isUserAccHasBusinessEntity && (
                      <StyledButton
                        size="m"
                        isB2b={isB2BSite}
                        haveMargin
                        data-testid="my-orders"
                        onClick={() => router?.push('/my-orders')}
                      >
                        My orders
                      </StyledButton>
                    )}
                  </>
                )}
                <StyledButton
                  size="m"
                  isB2b={isB2BSite}
                  haveMargin={!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity)}
                  data-testid="logout-button"
                  onClick={() => {
                    destroyeeAllNookies();
                    logout({
                      returnTo: window.location.origin,
                    });
                  }}
                >
                  Log out
                </StyledButton>
              </>
            )}
          </>
        )}
      </Login>
      {!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity) ? (
        <Cart
          deliveryModeB2B={isB2BSite}
          isMobile={isMobile}
          {...props}
          count={!cartState.isLoading ? cartState.items.length : 0}
          query={SCREEN_SIZE.From.Tablet}
        />
      ) : null}
    </>
  );
};

const CONFIRMATION = '/confirmation/';

const PageHeader = (props) => {
  const {
    router,
    track,
    splits,
    auth0: { loginWithRedirect, logout },
    isMobile,
  } = props;
  const { isEditMode, destroyeeAllNookies, setIsEditMode } = useContext(CustomiseVoucherContext);
  const {
    appState: { siteType },
  } = useAppContext();
  const { isB2BSite } = useBusinessEntityData();
  const ref = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const isPaymentPage = ['/payment/', CONFIRMATION].includes(window.location?.pathname);
  const isBusinessFeatureEnable = splits?.[SPLIT_FEATURES.GIFTING_BUSINESS]?.treatment === 'on';
  const isMyordersEnable = splits?.[SPLIT_FEATURES.MY_ORDERS]?.treatment === 'on';
  const label = isB2BSite ? 'Business' : 'Personal';

  const handleCloseClick = () => {
    if (['/customise-voucher/'].includes(window.location?.pathname)) {
      track({
        [GEV.PAGE_TYPE]: PAGE_TYPES.VOUCHER,
        [GEV.SCREEN_NAME]: SCREEN_NAME.CHECKOUT,
        [GEV.EVENT_CAT]: 'Egifting',
        [GEV.EVENT_ACTION]: 'click',
        [GEV.EVENT_LABEL]: 'gifting_checkout_back',
      });
    }
  };

  const handleNavigationClick = (isMobileParam, trackParam, index) => {
    trackParam({
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.TOGGLE_POPUP_VIEWED,
      [GEV.MEMBER_TYPE]: siteType,
      [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
      [GEV.AMPLITUDE_CTA_LOCATION]: `egifting | ${getToggleLabel()}`,
      [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | egifting portal',
    });
    if (
      ['/customise-voucher/', '/cart/', '/my-orders/', '/my-orders/details/'].includes(
        window.location.pathname
      )
    ) {
      if (siteType === B2C && index === 0) {
        window.location.href = '/';
      } else {
        setIsModalOpen(true);
      }
    } else if (isMobileParam) {
      window.location.href = `/?type=${index === 0 ? B2C : B2B}`;
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <GlobalPopoverStyle />
      <GlobalTopPaddingBody />
      <CancelModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSwitching
        onSwitchClick={() => {
          setIsOpen(false);
          window.location.href = `/?type=${isB2BSite ? B2C : B2B}`;
        }}
        deliveryModeB2B={isB2BSite}
        track={track}
      />

      <StyledWrapper
        isBusiness={isB2BSite}
        ref={ref}
        data-testid="page-header-navigation"
        isPaymentPage={isPaymentPage}
      >
        <Popup
          open={isOpen}
          onClose={() => setIsOpen(false)}
          deliveryModeB2B={isB2BSite}
          onSelection={(selected) => {
            track({
              [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.TOGGLE_POPUP_CLICK,
              [GEV.MEMBER_TYPE]: siteType,
              [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
              [GEV.AMPLITUDE_CTA_LOCATION]: 'egifting | home',
              [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | egifting portal',
              [GEV.AMPLITUDE_GIFTING_MESSAGE]: `switch to ${label}`,
            });
            setIsOpen(false);
            const url = new URL(window.location.href);
            url.searchParams.set('type', selected);
            window.location.href = url;
          }}
        />
        <StyledHeaderContainer id="styledHeader">
          <StyledHeaderTop isb2b={isB2BSite} isPaymentPage={isPaymentPage}>
            {!isEditMode ? (
              <div id="hamburger">
                <HamburgerMenuIconWrapper
                  isbusiness={isB2BSite.toString()}
                  onClick={() => setIsMobileNavOpen(true)}
                  data-testid="open-mobilenav-button"
                />
                <MobileNavigation
                  handleClose={() => {
                    disablePageScroll(false);
                    setIsMobileNavOpen(false);
                  }}
                  navShow={isMobileNavOpen}
                  router={router}
                  destroyeeAllNookies={destroyeeAllNookies}
                  data-testid="close-mobilenav-button"
                  deliveryModeB2B={isB2BSite}
                  isMyordersEnable={isMyordersEnable}
                  handleNavigationClick={(index) => handleNavigationClick(true, track, index)}
                />
              </div>
            ) : null}

            <BrandIcons data-testid="fairPriceLogo" isBusiness={isB2BSite}>
              <StyledBrandIcons>
                <Grid className="no-spacing flex max-height">
                  <StyledImg
                    onClick={() => {
                      router?.push('/');
                      window.location.href = '/';
                    }}
                    alt=""
                    src={isB2BSite ? FPWhite.src : FPG50.src}
                    data-testid="home-screen-btn-white"
                  />
                  {!isEditMode &&
                    !['/payment/', '/customise-voucher/?slug=', CONFIRMATION].includes(
                      window.location?.pathname
                    ) &&
                    isBusinessFeatureEnable && (
                      <HeaderBusiness
                        isMobile={isMobile}
                        deliveryModeB2B={isB2BSite}
                        handlerOnClick={() => handleNavigationClick(false, track)}
                      />
                    )}
                </Grid>
              </StyledBrandIcons>
            </BrandIcons>
            {window.location?.pathname === '/payment/' ||
            window.location?.pathname === CONFIRMATION ||
            isEditMode === true ? (
              <>
                <CloseBtn isB2bEnabled={isB2BSite}>
                  <ButtonIcon
                    onClick={() => {
                      router?.push('/cart/');
                      window.location.href = '/cart/';
                      setIsEditMode(false);
                      handleCloseClick();
                    }}
                  >
                    <SvgClose data-testid="svgclose" size="m" />
                  </ButtonIcon>
                </CloseBtn>
              </>
            ) : (
              <CartWrp data-testid="cart-wrap">
                <RenderLogin
                  loginWithRedirect={loginWithRedirect}
                  destroyeeAllNookies={destroyeeAllNookies}
                  logout={logout}
                  isMobile={isMobile}
                  isMyordersEnable={isMyordersEnable}
                  {...props}
                />
              </CartWrp>
            )}
          </StyledHeaderTop>
        </StyledHeaderContainer>
      </StyledWrapper>
    </>
  );
};

const PageHeaderWrapper = (props) => {
  const router = useRouter();
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < breakpoints.tablet);
    };

    window.addEventListener('resize', _debounce(updateWindowDimensions, RESIZING_TIMER));

    updateWindowDimensions();

    return () =>
      window.removeEventListener('resize', _debounce(updateWindowDimensions, RESIZING_TIMER));
  }, []);

  return (
    <EventTrackingContext.Consumer>
      {({ track }) => (
        <PageHeader {...props} isMobile={isMobile} splits={splits} router={router} track={track} />
      )}
    </EventTrackingContext.Consumer>
  );
};

export default withAuth0(PageHeaderWrapper);
